// frameCardName: Molten Disaster
// frameCardSet: FUT

import * as styles from './404.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

interface Props {
  pageContext: any
}

const MissingPage: React.FC<Props> = (props) => {
  return (
    <Layout {...props.pageContext} accentColor="#B63622">
      <div className={styles.container}>
        <Typography.PrimaryHeading>
          “Life has no mistakes, only experiments.”
        </Typography.PrimaryHeading>
        <div className={styles.byline}>—Yolov, Simic bioengineer</div>
        <Typography.Paragraph>
          Ok but actually it seems like we messed up because we can&rsquo;t find
          the page you&rsquo;re looking for. It&rsquo;s most likely one of our{' '}
          <Link to="/articles/">articles</Link>, but if you can&rsquo;t find it
          email us at <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a>
          .
        </Typography.Paragraph>
      </div>
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Page Not Found - Lucky Paper',
        description:
          'Page not found on Lucky Paper, a site about Magic the Gathering focused on sandbox formats.',
      }}
    />
  )
}

export default MissingPage
